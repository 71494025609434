import 'react-hot-loader/patch'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import TagManager from 'react-gtm-module'
import { AppContainer, setConfig as hotLoaderConfig } from 'react-hot-loader'
import { ReactQueryConfigProvider } from 'react-query'

import { App } from './App'
import { GOOGLE_CLIENT_ID } from './constants'
import { FeatureContextProvider } from './contexts/FeatureContext.js'
import { GlobalContextProvider } from './contexts/GlobalContext.js'

TagManager.initialize({
  gtmId: 'GTM-TF5SHQV',
})

hotLoaderConfig({
  showReactDomPatchNotification: false,
})

const render = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID:
      process.env.NODE_ENV === 'production'
        ? '64f9e81c39c110121d698166'
        : '64f9e81c39c110121d698165',
  })

  const queryConfig = {
    queries: {
      staleTime: 30000, // keep data fresh for 30 seconds to reduce aggressive fetching
    },
  }

  ReactDOM.render(
    <AppContainer>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <LDProvider>
          <ReactQueryConfigProvider config={queryConfig}>
            <FeatureContextProvider>
              <GlobalContextProvider>
                <App />
              </GlobalContextProvider>
            </FeatureContextProvider>
          </ReactQueryConfigProvider>
        </LDProvider>
      </GoogleOAuthProvider>
    </AppContainer>,
    document.getElementById('root')
  )
}

render(App)

if (module.hot) {
  module.hot.accept('./App.js', () => {
    const NextRootContainer = require('./App.js').default
    render(NextRootContainer)
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

import { Result, Button, Alert } from 'antd'
import React, { useContext } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useQuery } from 'react-query'
import styled from 'styled-components/macro'

import Sidebar from './Sidebar'
import { ErrorFallBack } from '../components/general/ErrorFallback'
import { BrandContext } from '../contexts/BrandContext'
import { UserContext } from '../contexts/UserContext'

const Layout = ({ children }) => {
  const { role } = useContext(UserContext)
  const { brandId, fetchBrandData, openBilling } = useContext(BrandContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)
  const mobileResponsiveRoutes = [
    '/pricing',
    '/welcome',
    '/account',
    '/profile',
    '/social-listening',
    '/analytics',
  ]
  const route = window.location.pathname

  return (
    <ErrorBoundary fallback={<ErrorFallBack />} showDialog={true}>
      <Wrapper>
        {/* {new Error('Test')} */}
        <Sidebar />
        <div className='content'>
          {!mobileResponsiveRoutes.includes(route) && (
            <div className='mobile-alert'>
              <Alert
                type='warning'
                message={`It looks like you're using a mobile device. This dashboard is not optimized for smaller screens. Please use a tablet or desktop computer.`}
                showIcon
                closable
              />
            </div>
          )}
          {brandData?.chargebeePastDue && role === 'administrator' && (
            <Alert
              type='warning'
              message='This account is past due on their payments. The brand has been blocked from accessing their campaigns and search tool.'
              showIcon
            />
          )}
          {brandData?.chargebeePastDue && role !== 'administrator' ? (
            <div className='dashboard-result'>
              <Result
                status='warning'
                title='Payment Past Due'
                subTitle='It looks like you have a payment that is overdue. You will not be able to access your dashboard until it is paid in full. Please update your billing details.'
                extra={
                  <Button type='primary' onClick={openBilling}>
                    Open Billing
                  </Button>
                }
              />
            </div>
          ) : (
            children
          )}
        </div>
      </Wrapper>
    </ErrorBoundary>
  )
}

const Wrapper = styled.div`
  height: calc(100vh - 50px);
  overflow: hidden;
  display: flex;

  & > .content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    scroll-behavior: smooth;

    .notice-board {
      padding: 12px;
      margin: 0 auto;
    }
  }

  // hide mobile alert on tablet
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .mobile-alert {
      display: none;
    }
  }
`

export default Layout

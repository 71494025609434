export const NODE_URL = `${process.env.REACT_APP_NODE_URL}`
export const IDM_URL = `${process.env.REACT_APP_IDM_URL}`
export const AUTH_DASHBOARD_DOMAIN = `${process.env.REACT_APP_AUTH_DASHBOARD_DOMAIN}`

export const BOOK_DEMO_URL = 'https://app.apollo.io/#/meet/vkj-7m1-ftu/30-min'

export const CHARGEBEE_SITE =
  process.env.NODE_ENV === 'production' ? 'shopandshout' : 'shopandshout-test'

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID

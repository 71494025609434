import React, { useEffect, useContext } from 'react'
import { Navigate } from 'react-router-dom'

import { UserContext } from '../contexts/UserContext'

export const Logout = () => {
  const { logoutHandler } = useContext(UserContext)

  useEffect(() => {
    logoutHandler()
  }, [logoutHandler])

  return <Navigate to='/login' />
}

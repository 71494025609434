import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'

import { BrandContext } from '../contexts/BrandContext'
import { UserContext } from '../contexts/UserContext'

export const useLaunchDarkly = () => {
  const ldClient = useLDClient()

  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { idmToken, fetchCurrentUserData, userContextConfig } = useContext(UserContext)
  const userToken = idmToken

  const { data: brandData, status: brandStatus } = useQuery(['brand', brandId], fetchBrandData)
  const { data: userData, status: userStatus } = useQuery(
    ['current-user', userToken],
    fetchCurrentUserData,
    userContextConfig
  )

  useEffect(() => {
    if (userData || brandData) {
      const context = {
        kind: 'multi',
        user:
          userData && userStatus === 'success'
            ? {
                key: `u-${userData.id}`,
                name: `${userData.firstName} ${userData.lastName}`,
                email: userData.email,
                role: userData.role,
              }
            : undefined,
        brand:
          brandData && brandStatus === 'success'
            ? {
                key: `b-${brandData.id}`,
                name: brandData.name,
              }
            : undefined,
      }

      ldClient.identify(context)
    }
  }, [userData, userStatus, brandData, brandStatus, ldClient])
}

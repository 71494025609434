import React, { useContext, lazy } from 'react'
import { RouterProvider, createBrowserRouter, Outlet, Navigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'antd/dist/antd.css'
import Cookies from 'universal-cookie'

import { BookDemo } from './components/BookDemo'
import DemoConfirmation from './components/DemoConfirmation'
import ShopifyInstall from './components/general/ShopifyInstall'
import { Login } from './components/Login'
import { Logout } from './components/Logout'
import { BrandContextProvider } from './contexts/BrandContext'
import { CampaignContextWrapper } from './contexts/CampaignContextWrapper'
import { GlobalContext } from './contexts/GlobalContext'
import Pricing from './layout/pricing/Pricing'
import { SuperBase } from './SuperBase'

const CampaignDashboard = lazy(() => import('./layout/CampaignDashboard'))
const SingleCampaign = lazy(() => import('./components/campaign-dashboard/SingleCampaign'))
const EditCampaign = lazy(() => import('./layout/edit-campaign/EditCampaign'))
const Welcome = lazy(() => import('./layout/Welcome'))
const CreatorSearch = lazy(() => import('./layout/creator-search/CreatorSearch'))
const SocialListening = lazy(() => import('./layout/social-listening/SocialListening'))
const AnalyticsDashboard = lazy(() => import('./layout/analytics/AnalyticsDashboard'))
const Affiliate = lazy(() => import('./layout/affiliate/Affiliate'))
const Inbox = lazy(() => import('./layout/chat/Inbox'))
const BrandSettings = lazy(() => import('./layout/account/BrandSettings'))
const UserSettings = lazy(() => import('./layout/user-settings/UserSettings'))

export const Main = () => {
  const cookies = new Cookies()
  const { idmToken } = useContext(GlobalContext)

  const CampaignRoutesWrapper = () => {
    return (
      <CampaignContextWrapper>
        <Outlet />
      </CampaignContextWrapper>
    )
  }

  let params = new URL(document.location).searchParams
  if (params.get('utm_campaign'))
    cookies.set('utm_campaign', params.get('utm_campaign'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('utm_medium'))
    cookies.set('utm_medium', params.get('utm_medium'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('utm_source'))
    cookies.set('utm_source', params.get('utm_source'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('utm_content'))
    cookies.set('utm_content', params.get('utm_content'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('utm_term'))
    cookies.set('utm_term', params.get('utm_term'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('hmac')) localStorage.setItem('hmac', params.get('hmac'))

  if (params.get('shop')) localStorage.setItem('shop', params.get('shop'))

  if (params.get('code')) localStorage.setItem('code', params.get('code'))

  const router = createBrowserRouter([
    {
      path: '/',
      element: <SuperBase />,
      children: [
        { path: 'book-a-demo', element: <BookDemo /> },
        { path: 'demo-confirmation', element: <DemoConfirmation /> },
        { path: 'login', element: <Login /> },
        { path: 'signup', element: <Login /> },
        { path: 'logout', element: <Logout /> },
        { path: 'order-integration-install', element: <ShopifyInstall /> },
        { path: 'pricing', element: <Pricing /> },

        idmToken
          ? {
              path: '',
              children: [
                {
                  path: '',
                  element: <CampaignRoutesWrapper />,
                  children: [
                    { path: '', element: <CampaignDashboard /> },
                    { path: 'campaigns', element: <CampaignDashboard /> },
                    { path: 'campaigns/:campaignID/:tab', element: <SingleCampaign /> },
                    { path: 'campaigns/:campaignID', element: <SingleCampaign /> },
                    { path: 'campaign-builder/:campaignId', element: <EditCampaign /> },
                    { path: '/', element: <Navigate to='campaigns' replace /> },
                  ],
                },
                { path: 'welcome', element: <Welcome /> },
                { path: 'creator-search', element: <CreatorSearch /> },
                { path: 'creator-search/:tab', element: <CreatorSearch /> },
                { path: 'social-listening', element: <SocialListening /> },
                { path: 'analytics', element: <AnalyticsDashboard /> },
                { path: 'affiliate/:tab', element: <Affiliate /> },
                { path: 'inbox/:campaignId/chat/:optInId', element: <Inbox /> },
                { path: 'inbox/:campaignId/', element: <Inbox /> },
                { path: 'inbox', element: <Inbox /> },
                { path: 'account', element: <BrandSettings /> },
                { path: 'profile', element: <UserSettings /> },
                { path: 'pricing/:planId', element: <Pricing /> },
                { path: 'pricing', element: <Pricing /> },
                { path: '*', element: <Navigate to='campaigns' replace /> },
              ],
            }
          : {
              path: '',
              element: <Navigate to='/login' replace />,
            },

        { path: '*', element: <Navigate to='/login' replace /> },
      ],
    },
  ])

  return (
    <BrandContextProvider>
      <RouterProvider router={router} />
    </BrandContextProvider>
  )
}
